import '../App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import { SocialIcon } from 'react-social-icons';
import nft from '../assets/mint.gif';
import logo from '../assets/logo.png';

var Scroll = require('react-scroll');

const discord = () => {
  window.open("https://discord.gg/byanon");
}

const twitter = () => {
  window.open("https://twitter.com/A__________by_A");
}

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let onlyLeft;
let owner;
let publicSale;
let wMintAmount;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let special_mint_status = false;
let paused;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [

];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_initBaseURI",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_initNotRevealedUri",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_contractURI",
        "type": "string"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [],
    "name": "ApprovalCallerNotOwnerNorApproved",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ApprovalQueryForNonexistentToken",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ApprovalToCurrentOwner",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ApproveToCaller",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "BalanceQueryForZeroAddress",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "MintToZeroAddress",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "MintZeroQuantity",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      }
    ],
    "name": "OperatorNotAllowed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "OwnerIndexOutOfBounds",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "OwnerQueryForNonexistentToken",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "TokenIndexOutOfBounds",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "TransferCallerNotOwnerNorApproved",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "TransferFromIncorrectOwner",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "TransferToNonERC721ReceiverImplementer",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "URIQueryForNonexistentToken",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "approved",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "ApprovalForAll",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "OPERATOR_FILTER_REGISTRY",
    "outputs": [
      {
        "internalType": "contract IOperatorFilterRegistry",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_nft_ContractA",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "_nft_ContractB",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "receiver",
        "type": "address[]"
      },
      {
        "internalType": "uint256[]",
        "name": "quantity",
        "type": "uint256[]"
      }
    ],
    "name": "airdrop",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "baseURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenID",
        "type": "uint256"
      }
    ],
    "name": "claimNFTsA",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenID",
        "type": "uint256"
      }
    ],
    "name": "claimNFTsB",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "contractURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "freeMintA",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "freeMintB",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "getApproved",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      }
    ],
    "name": "isApprovedForAll",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "max_per_wallet",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "addr",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "quantity",
        "type": "uint256"
      }
    ],
    "name": "mintBlackBox",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "nftA",
    "outputs": [
      {
        "internalType": "contract IERC721",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "nftB",
    "outputs": [
      {
        "internalType": "contract IERC721",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "notRevealedUri",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "name": "onERC721Received",
    "outputs": [
      {
        "internalType": "bytes4",
        "name": "",
        "type": "bytes4"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "ownerOf",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "paused",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "publicMinted",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "publicSaleCost",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "public_mint_status",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "revealed",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_tokenId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_salePrice",
        "type": "uint256"
      }
    ],
    "name": "royaltyInfo",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "safeTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "safeTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "setApprovalForAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_newBaseURI",
        "type": "string"
      }
    ],
    "name": "setBaseURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_contractURI",
        "type": "string"
      }
    ],
    "name": "setContractURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_freeMintA",
        "type": "uint256"
      }
    ],
    "name": "setFreeMintA",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_freeMintB",
        "type": "uint256"
      }
    ],
    "name": "setFreeMintB",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_MAX_SUPPLY",
        "type": "uint256"
      }
    ],
    "name": "setMAX_SUPPLY",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_max_per_wallet",
        "type": "uint256"
      }
    ],
    "name": "setMax_per_wallet",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_nftContractA",
        "type": "address"
      }
    ],
    "name": "setNFTContractA",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_nftContractB",
        "type": "address"
      }
    ],
    "name": "setNFTContractB",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_notRevealedURI",
        "type": "string"
      }
    ],
    "name": "setNotRevealedURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_publicSaleCost",
        "type": "uint256"
      }
    ],
    "name": "setPublicSaleCost",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_receiver",
        "type": "address"
      },
      {
        "internalType": "uint96",
        "name": "_royaltyFeesInBips",
        "type": "uint96"
      }
    ],
    "name": "setRoyaltyInfo",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_specialCost",
        "type": "uint256"
      }
    ],
    "name": "setSpecialCost",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_special_max_per_wallet",
        "type": "uint256"
      }
    ],
    "name": "setSpecial_max_per_wallet",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_total_PS_limit",
        "type": "uint256"
      }
    ],
    "name": "set_total_PS_limit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "specialCost",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "specialCountALL",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "special_limit",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "special_max_per_wallet",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "special_mint_status",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "stakedSpecialCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "interfaceId",
        "type": "bytes4"
      }
    ],
    "name": "supportsInterface",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "toggleReveal",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "toggle_paused",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "toggle_public_mint_status",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "tokenByIndex",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "tokenOfOwnerByIndex",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "tokenOwner",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "tokenURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "total_PS_count",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "total_PS_limit",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "usedTokenA",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "usedTokenB",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdraw",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
];

const address = "0xDEEDdAa9Bc54ec631EB83F0Cc8406ff163837168";

let contract;

class Mint extends Component {

  state = {
    walletAddress: "",
    totalSupply: "",
    currentPrice: "",
    nextPrice: "",
    nextSessionAmount: "",
    onlyLeftAmount: "",
    statusError: false,
    statusLoading: false,
    success: false,
    nftMintingAmount: '1',
    totalValue: "100000000000000000",
    maxmint: '',
    _adminPanel: 0,
    _adminWhitelistAddresses: [],
    _adminWhitelistAddresses2: [],
    _maxTokensToBuy: "",
    _testingValue: '',
    _wlMint: '',
    _FinalResult: '',
    _wlMsg: 'HOLDER CLAIM',
    _public_sale_cost: '',
    _MAX_PER_WALLET: '',
    _public_mint_status: '',
    _special_mint_status: '',
    _cost: '',
    _mintStatus: false,
    _paused: false,
    _whitelistCost: '',
    _balanceA: '',
    _balanceB: '',
    _claimA: '',
    _claimB: ''
  }

  onSubmitMinus = async event => {
    event.preventDefault();

    mintAmount = mintAmount - 1;

    if (mintAmount < 1) {
      mintAmount = 1
    }


    if (owner == account) {
      console.log("owner : " + owner)
      onlyLeft = 1000 - totalSupplyNFT;

      if (mintAmount > onlyLeft) {
        mintAmount = onlyLeft;
      }

      valueOfNFTs = mintAmount * 0;
      wMintAmount = mintAmount;

      this.setState({ nftMintingAmount: mintAmount });

      this.setState({ totalValue: valueOfNFTs });
    } else {


      if (totalSupplyNFT < 1000) {

        onlyLeft = MAX_PER_WALLET - publicSale;

        if (mintAmount > onlyLeft) {
          mintAmount = onlyLeft;
        }
        valueOfNFTs = mintAmount * public_sale_cost;
        wMintAmount = mintAmount;


        this.setState({ nftMintingAmount: mintAmount });

        this.setState({ totalValue: valueOfNFTs });

      }
    }
  }

  onSubmitPlus = async event => {
    event.preventDefault();

    //,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
    mintAmount = mintAmount + 1;

    if (owner == account) {
      console.log("owner : " + owner)
      onlyLeft = 1000 - totalSupplyNFT;

      if (mintAmount > onlyLeft) {
        mintAmount = onlyLeft;
      }

      valueOfNFTs = mintAmount * 0;
      wMintAmount = mintAmount;


      this.setState({ nftMintingAmount: mintAmount });

      this.setState({ totalValue: valueOfNFTs });
    } else {

      if (totalSupplyNFT < 1000) {

        onlyLeft = MAX_PER_WALLET - publicSale;
        console.log(onlyLeft);

        if (mintAmount > onlyLeft) {
          mintAmount = onlyLeft;
        }
        valueOfNFTs = mintAmount * public_sale_cost;
        wMintAmount = mintAmount;

        this.setState({ nftMintingAmount: mintAmount });

        this.setState({ totalValue: valueOfNFTs });

      }
    }
  }

  onSubmit2 = async event => {
    event.preventDefault();

    console.log(this.state.walletAddress);


    try {
      let owner = await contract.methods.owner().call();

      if (account != owner) {


        try {


          console.log("minAmount:" + mintAmount);
          console.log("valueOfNFTs:" + valueOfNFTs);
          console.log("cost : " + this.state.totalValue);

          this.setState({ statusError: false, statusLoading: true });
          await contract.methods.mintBlackBox(account, mintAmount * 1).send({ gasLimit: 385000, from: account, value: mintAmount * this.state._public_sale_cost });
          this.setState({ statusLoading: false, success: true });
          console.log("Mint Amount :" + mintAmount);


        } catch (err) {
          this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
          console.log(err);


        }

      } else {

        try {


          console.log("minAmount:" + mintAmount);
          console.log("valueOfNFTs:" + valueOfNFTs);


          this.setState({ statusError: false, statusLoading: true });
          await contract.methods.mintBlackBox(account, mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });
          this.setState({ statusLoading: false, success: true });
          console.log("Mint Amount :" + this.state.mintAmount);



        } catch (err) {

          this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
          console.log(err);

        }
      }
    } catch (err) {

      console.log(err);

    }
  }

  walletConnect = async event => {
    event.preventDefault();

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
        }
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK, // Required
        options: {
          appName: "Aterium Universe", // Required
          infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
          rpc: "", // Optional if `infuraId` is provided; otherwise it's required
          chainId: 1, // Optional. It defaults to 1 if not provided
          darkMode: true // Optional. Use dark theme, defaults to false
        }
      }
    };

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });



    const provider = await web3Modal.connect();

    //  Enable session (triggers QR Code modal)
    await provider.enable();

    const web3 = new Web3(provider);
    console.log("provider : " + provider);
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      console.log(accounts);
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      console.log(chainId);
    });

    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log(info);
      console.log("I'm LOGGED IN");
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log(error);
    });

    // test if wallet is connected
    if (web3Modal.cachedProvider) {
      // connected now you can get accounts
      console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
      console.log("provider :" + provider);

      const accounts = await web3.eth.getAccounts();

      account = accounts[0];
      this.setState({ walletAddress: account });

      contract = new web3.eth.Contract(ABI, address);

      console.log("contract :" + contract);

      if (provider) {


        //	(async () => {

        if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

          const chainId = 1;

          if (window.ethereum.networkVersion !== chainId) {
            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }],
              });
            } catch (err) {
              // This error code indicates that the chain has not been added to MetaMask.
              if (err.code === 4902) {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainName: 'Ethereum Mainnet',
                      chainId: web3.utils.toHex(chainId),
                      nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
                      rpcUrls: ['https://mainnet.infura.io/v3/'],
                      /*chainName: 'Rinkeby Test Network',
                      chainId: web3.utils.toHex(chainId),
                      nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
                      rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
                    },
                  ],
                });
              }
            }
          }



          try {

            paused = await contract.methods.paused().call();
            if (paused == true) {
              paused = "true";
            } else {
              paused = "false";
            }
            this.setState({ _paused: paused });

            totalSupplyNFT = await contract.methods.totalSupply().call();
            this.setState({ totalSupply: totalSupplyNFT });
            console.log("Total Supply:" + totalSupplyNFT);

            publicSale = await contract.methods.publicMinted(account).call();
            this.setState({ myNFTWallet: publicSale });

            public_sale_cost = await contract.methods.publicSaleCost().call();
            this.setState({ _public_sale_cost: public_sale_cost });
            console.log("public_sale_cost :" + public_sale_cost);

            MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
            this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
            console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

            public_mint_status = await contract.methods.public_mint_status().call();
            this.setState({ _public_mint_status: public_mint_status });
            console.log("public_mint_status :" + public_mint_status);

            special_mint_status = await contract.methods.special_mint_status().call();
            this.setState({ _special_mint_status: special_mint_status });
            console.log("special_mint_status :" + special_mint_status);

            owner = await contract.methods.owner().call();
            console.log("Owner" + owner);

            if (owner == account) {
              console.log("owner : " + owner)
              onlyLeft = 1000 - totalSupplyNFT;

              if (mintAmount > onlyLeft) {
                mintAmount = onlyLeft;
              }

              valueOfNFTs = mintAmount * 0;
              wMintAmount = mintAmount;


              this.setState({ nftMintingAmount: mintAmount });

              this.setState({ totalValue: valueOfNFTs });
            } else {
              mintAmount = 1;

              if (totalSupplyNFT == 1000) {

                onlyLeft = 1000 - totalSupplyNFT;
                mintAmount = onlyLeft;
                this.setState({ msg: "SOLD OUT" });

              } else {
                mintAmount = 1;
                onlyLeft = MAX_PER_WALLET - publicSale;

                if (mintAmount > onlyLeft) {
                  mintAmount = onlyLeft;
                }
                //mintAmount = onlyLeft;

                valueOfNFTs = mintAmount * public_sale_cost;
                wMintAmount = mintAmount;

                this.setState({ nftMintingAmount: mintAmount });

                this.setState({ totalValue: valueOfNFTs });
              }
            }


          } catch (err) {
            console.log("err: " + err);

          }
        } else if (web3Modal.cachedProvider == "walletconnect") {

          const chainId = 1;

          if (WalletConnectProvider.networkVersion !== chainId) {
            try {
              await WalletConnectProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }],
              });
            } catch (err) {
              // This error code indicates that the chain has not been added to MetaMask.
              if (err.code === 4902) {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainName: 'Ethereum Mainnet',
                      chainId: web3.utils.toHex(chainId),
                      nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
                      rpcUrls: ['https://mainnet.infura.io/v3/'],

                      /*chainName: 'Mumbai Testnet',
                      chainId: web3.utils.toHex(chainId),
                      nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                      rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
                    },
                  ],
                });
              }
            }
          }


          try {

            paused = await contract.methods.paused().call();
            if (paused == true) {
              paused = "true";
            } else {
              paused = "false";
            }
            this.setState({ _paused: paused });

            totalSupplyNFT = await contract.methods.totalSupply().call();
            this.setState({ totalSupply: totalSupplyNFT });
            console.log("Total Supply:" + totalSupplyNFT);

            publicSale = await contract.methods.publicMinted(account).call();
            this.setState({ myNFTWallet: publicSale });

            public_sale_cost = await contract.methods.publicSaleCost().call();
            this.setState({ _public_sale_cost: public_sale_cost });
            console.log("public_sale_cost :" + public_sale_cost);

            MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
            this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
            console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

            public_mint_status = await contract.methods.public_mint_status().call();
            this.setState({ _public_mint_status: public_mint_status });
            console.log("public_mint_status :" + public_mint_status);

            special_mint_status = await contract.methods.special_mint_status().call();
            this.setState({ _special_mint_status: special_mint_status });
            console.log("special_mint_status :" + special_mint_status);

            owner = await contract.methods.owner().call();
            console.log("Owner" + owner);

            if (owner == account) {
              console.log("owner : " + owner)
              onlyLeft = 1000 - totalSupplyNFT;

              if (mintAmount > onlyLeft) {
                mintAmount = onlyLeft;
              }

              valueOfNFTs = mintAmount * 0;

              wMintAmount = mintAmount;


              this.setState({ nftMintingAmount: mintAmount });

              this.setState({ totalValue: valueOfNFTs });
            } else {
              mintAmount = 1;

              if (totalSupplyNFT == 1000) {

                onlyLeft = 0;

                if (mintAmount > onlyLeft) {
                  mintAmount = onlyLeft;
                }
                this.setState({ msg: "SOLD OUT" });

              } else {
                mintAmount = 1;
                onlyLeft = MAX_PER_WALLET - publicSale;

                if (mintAmount > onlyLeft) {
                  mintAmount = onlyLeft;
                }

                valueOfNFTs = mintAmount * public_sale_cost;
                wMintAmount = mintAmount;


                this.setState({ nftMintingAmount: mintAmount });

                this.setState({ totalValue: valueOfNFTs });
              }
            }


          } catch (err) {
            console.log("err: " + err);

          }
        } else if (web3Modal.cachedProvider == "coinbasewallet") {

          const chainId = 1;

          if (CoinbaseWalletProvider.networkVersion !== chainId) {
            try {
              await CoinbaseWalletProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }],
              });
            } catch (err) {
              // This error code indicates that the chain has not been added to MetaMask.
              if (err.code === 4902) {
                await CoinbaseWalletProvider.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainName: 'Ethereum Mainnet',
                      chainId: web3.utils.toHex(chainId),
                      nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
                      rpcUrls: ['https://mainnet.infura.io/v3/'],

                      /*chainName: 'Mumbai Testnet',
                      chainId: web3.utils.toHex(chainId),
                      nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                      rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
                    },
                  ],
                });
              }
            }
          }



          try {

            paused = await contract.methods.paused().call();
            if (paused == true) {
              paused = "true";
            } else {
              paused = "false";
            }
            this.setState({ _paused: paused });

            totalSupplyNFT = await contract.methods.totalSupply().call();
            this.setState({ totalSupply: totalSupplyNFT });
            console.log("Total Supply:" + totalSupplyNFT);

            publicSale = await contract.methods.publicMinted(account).call();
            this.setState({ myNFTWallet: publicSale });

            public_sale_cost = await contract.methods.publicSaleCost().call();
            this.setState({ _public_sale_cost: public_sale_cost });
            console.log("public_sale_cost :" + public_sale_cost);

            MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
            this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
            console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

            public_mint_status = await contract.methods.public_mint_status().call();
            this.setState({ _public_mint_status: public_mint_status });
            console.log("public_mint_status :" + public_mint_status);

            special_mint_status = await contract.methods.special_mint_status().call();
            this.setState({ _special_mint_status: special_mint_status });
            console.log("special_mint_status :" + special_mint_status);

            owner = await contract.methods.owner().call();
            console.log("Owner" + owner);

            if (owner == account) {
              console.log("owner : " + owner)
              onlyLeft = 1000 - totalSupplyNFT;

              if (mintAmount > onlyLeft) {
                mintAmount = onlyLeft;
              }

              valueOfNFTs = mintAmount * 0;
              wMintAmount = mintAmount;


              this.setState({ nftMintingAmount: mintAmount });

              this.setState({ totalValue: valueOfNFTs });
            } else {
              mintAmount = 1;

              if (totalSupplyNFT == 1000) {

                onlyLeft = 1000 - totalSupplyNFT;
                mintAmount = onlyLeft;
                this.setState({ msg: "SOLD OUT" });

              } else {
                mintAmount = 1;
                onlyLeft = MAX_PER_WALLET - publicSale;

                if (mintAmount > onlyLeft) {
                  mintAmount = onlyLeft;
                }
                //mintAmount = onlyLeft;

                valueOfNFTs = mintAmount * public_sale_cost;
                wMintAmount = mintAmount;


                this.setState({ nftMintingAmount: mintAmount });

                this.setState({ totalValue: valueOfNFTs });
              }
            }


          } catch (err) {
            console.log("err: " + err);

          }


        }


        //})();

        //......................................................................//

        // Legacy providers may only have ethereum.sendAsync
        const chainId = await provider.request({
          method: 'eth_chainId'
        })

      } else {

        // if the provider is not detected, detectEthereumProvider resolves to null
        console.error('Please install a Valid Wallet');
        alert('A valid provider could not be found!');

      }

    }

  }

  walletDisconnect = async event => {
    event.preventDefault();


    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "9aad89c8e515457ab8b7805f5da593ea" // required
        }
      }
    };

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });



    // disconnect wallet
    web3Modal.clearCachedProvider();
    window.location.reload();

  }

  claimA = async event => {
    event.preventDefault();

    try {
      await contract.methods.claimNFTsA(this.state._claimA * 1).send({ gasLimit: 385000, from: account, value: 0 });
    } catch (err) {
      console.log(err);
    }

  }

  claimB = async event => {
    event.preventDefault();

    try {
      await contract.methods.claimNFTsB(this.state._claimB * 1).send({ gasLimit: 385000, from: account, value: 0 });
    } catch (err) {
      console.log(err);
    }

  }

  render() {

    return (

      <div class="allWrap">
        <div class="mint2">

          <div class="navBarStake">
            <div class="navBarStake2">
              <div class="tName"><img class="logoNFT" onClick={() => window.location.href = '/'} src={logo} /></div>

              <div class="connect">
                <div id="fontSizeActive">Mint/Claim</div>
                <div id="fontSize" onClick={() => window.location.href = '/stake'}>Stake</div>
                <div id="fontSize">About</div>
                <div id="fontSize" onClick={() => window.location.href = '/marketplace'}>Marketplace</div>
                <div id="fontSize" onClick={() => window.location.href = '/dao'}>DAO</div>
                <div id="fontSize" onClick={twitter}>Twitter</div>
                <div id="fontSize" onClick={discord}>Discord</div>

              </div>

              <div class="connectMobile">
                <div id="fontSizeActive" onClick={() => window.location.href = '/mint'}>Mint/Claim</div>
                <div id="fontSize" onClick={() => window.location.href = '/stake'}>Stake</div>
                <div id="fontSize" onClick={() => window.location.href = '/about'}>About</div>
                <div id="fontSize" onClick={() => window.location.href = '/marketplace'}>Marketplace</div>
                <div id="fontSize" onClick={() => window.location.href = '/dao'}>DAO</div>
              </div>


              <div class="walletAndMint">
                {this.state.walletAddress === '' ?
                  (<form class="stakeWCMain" onSubmit={this.walletConnect}>
                    <button class="kave-btn">
                      <span class="kave-line"></span>
                      Connect
                    </button>
                  </form>) : (<form class="stakeWCMain" onSubmit={this.walletDisconnect}><button class="kave-btn">
                    <span class="kave-line"></span>
                    {this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

                  </button>

                  </form>)}
              </div>
            </div>
          </div>

          <div class="introduction">

            <div class="beastDiv">
              <img class="beast" src={nft} />
            </div>



            <div class="placeAndWL">
              <form onSubmit={this.claimA}>
                <div>

                  <input type="text" name="setwlMintAmount" placeholder="Abstraction Token ID" value={this.state._claimA}
                    onChange={event => this.setState({ _claimA: event.target.value })}></input>

                  <button id="wlBtn" class="button-89" type="submit">
                    HOLDER CLAIM</button>
                </div>
              </form>

            </div>

            <div class="placeAndWL">
              <form onSubmit={this.claimB}>
                <div>

                  <input type="text" name="setwlMintAmount" placeholder="WIAINITAI Token ID" value={this.state._claimB}
                    onChange={event => this.setState({ _claimB: event.target.value })}></input>

                  <button id="wlBtn" class="button-89" type="submit">
                    HOLDER CLAIM</button>
                </div>
              </form>

            </div>

            <div><div class="mintDiv">
              <div class="totalSupply">{this.state.totalSupply} / 3333</div>
              {this.state._mintStatus === true ?
                (<div class="price"><div>Presale Mint {this.state._presalesale_cost / 1000000000000000000} ETH</div></div>) :
                (<div class="price"><div>Public Mint {this.state._public_sale_cost / 1000000000000000000} ETH</div></div>)}


              <div class="minting_count_button">
                <div class="center">
                  <form onSubmit={this.onSubmitMinus}>
                    <button class="btnfos-0-2" type="submit">-</button>
                  </form>
                </div>

                <div class="nftminter2Div">
                  <div class="nftminter2">{this.state.nftMintingAmount}</div>
                </div>


                <div class="center">
                  <form onSubmit={this.onSubmitPlus}>
                    <button class="btnfos-0-2-2" type="submit">+</button>
                  </form>
                </div>
              </div>

              <div class="mintbuttondiv">
                {this.state._mintStatus === true ? (
                  <div>

                    <form onSubmit={this.onSubmit2}>
                      <button class="btnfos-0-3" type="submit">
                        Presale Mint</button>
                    </form>

                  </div>
                ) : (<form onSubmit={this.onSubmit2}>
                  <button class="button-92" type="submit">
                    Public Mint</button>
                </form>)}
              </div>
              <div>

                {this.state.statusError ? (
                  <div class="errorMessage">
                    <div >Sorry, something went wrong please try again later</div>
                  </div>)
                  : null}

                {this.state.statusLoading ? (
                  <div class="loadingContainer">
                    <div>
                      <div class="loadingText">Minting your Anon</div>
                    </div>
                  </div>)
                  : null}

                {this.state.success ? (

                  <div><div class="successfully">MINTING SUCCESSFUL!</div>
                  </div>)
                  : null}

              </div></div>
            </div>

          </div>
          <div class="cMain">
            <p class="cLine"></p>
            <img src={logo} />
            <div>© 2022 ANON. All rights reserved.</div>
            <p class="cLine"></p>
          </div>
        </div >
      </div >)
  }
}

export default Mint;
