import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import StakeABYA from "./Pages/StakeABYA";
import Home from "./Pages/home";
import BYANON from "./Pages/BYANON";
import Stake from "./Pages/stake";
import About from "./Pages/about";
import Mint from "./Pages/mint";
import Marketplace from "./Pages/marketplace";
import DAO from "./Pages/dao";
import StakeAnon from "./Pages/StakeAnon";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/stakeABYA' element={<StakeABYA />} />
					<Route path='/stakeBYANON' element={<BYANON />} />
					<Route path='/stake' element={<Stake />} />
					<Route path='/' element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/mint' element={<Mint />} />
					<Route path='/marketplace' element={<Marketplace />} />
					<Route path='/dao' element={<DAO />} />
					<Route path='/stakeANONbyanon' element={<StakeAnon />} />

				</Routes>
			</BrowserRouter>
			<div>
		

			</div>
		</div>




	)
}


export default App;
