import '../App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import logo from '../assets/logo.png';

var Scroll = require('react-scroll');

const discord = () => {
    window.open("https://discord.gg/byanon");
}

const twitter = () => {
    window.open("https://twitter.com/A__________by_A");
}

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let onlyLeft;
let owner;
let publicSale;
let public_sale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let special_mint_status = false;
let paused;
let total_wl_limit;
let total_wl_count;
let balanceA;
let balanceB;


const ABI = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_initBaseURI",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_initNotRevealedUri",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_contractURI",
                "type": "string"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalToCurrentOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApproveToCaller",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "OperatorNotAllowed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerIndexOutOfBounds",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TokenIndexOutOfBounds",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "URIQueryForNonexistentToken",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "OPERATOR_FILTER_REGISTRY",
        "outputs": [
            {
                "internalType": "contract IOperatorFilterRegistry",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "receiver",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "quantity",
                "type": "uint256[]"
            }
        ],
        "name": "airdrop",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "baseURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "eligibleFreeMints",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "eligibleFreeMintsA",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "eligibleFreeMintsB",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "max_per_wallet",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "mintedFree",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nft",
        "outputs": [
            {
                "internalType": "contract IERC721",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nft2",
        "outputs": [
            {
                "internalType": "contract IERC721",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "notRevealedUri",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "publicMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicSaleCost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "public_mint_status",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "revealed",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_salePrice",
                "type": "uint256"
            }
        ],
        "name": "royaltyInfo",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_newBaseURI",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_contractURI",
                "type": "string"
            }
        ],
        "name": "setContractURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_MAX_SUPPLY",
                "type": "uint256"
            }
        ],
        "name": "setMAX_SUPPLY",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_max_per_wallet",
                "type": "uint256"
            }
        ],
        "name": "setMax_per_wallet",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_nftContract",
                "type": "address"
            }
        ],
        "name": "setNFTContract",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_nftContract2",
                "type": "address"
            }
        ],
        "name": "setNFTContract2",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_notRevealedURI",
                "type": "string"
            }
        ],
        "name": "setNotRevealedURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_publicSaleCost",
                "type": "uint256"
            }
        ],
        "name": "setPublicSaleCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_whitelistCost",
                "type": "uint256"
            }
        ],
        "name": "setWhitelistCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "newWhitelistSigner",
                "type": "bytes32"
            }
        ],
        "name": "setWhitelistSigner",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_total_PS_limit",
                "type": "uint256"
            }
        ],
        "name": "set_total_PS_limit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_total_special_limit",
                "type": "uint256"
            }
        ],
        "name": "set_total_special_limit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_total_wl_limit",
                "type": "uint256"
            }
        ],
        "name": "set_total_wl_limit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_specialNFTHoldersCost",
                "type": "uint256"
            }
        ],
        "name": "setspecialNFTHoldersCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "specialMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "specialNFTHoldersCost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "special_mint_status",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggleReveal",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggle_paused",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggle_public_mint_status",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggle_special_mint_status",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggle_wl_mint_status",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenOfOwnerByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "total_PS_count",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "total_PS_limit",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "total_special_count",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "total_special_limit",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "total_wl_count",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "total_wl_limit",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "whitelistCost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32[]",
                "name": "_proof",
                "type": "bytes32[]"
            },
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "whitelistMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "whitelistSigner",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "wlMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "wl_mint_status",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const address = "0x5dFb15E2564d0EF3ec9Df987FD7A8574D738AFd6";

const oldAABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalToCurrentOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApproveToCaller",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "OperatorNotAllowed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "_amountPerAddress",
                "type": "uint8"
            },
            {
                "internalType": "address[]",
                "name": "addresses",
                "type": "address[]"
            }
        ],
        "name": "Airdrop",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "NFTPerPublicAddress",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint16",
                "name": "_mintAmount",
                "type": "uint16"
            },
            {
                "internalType": "address",
                "name": "_receiver",
                "type": "address"
            }
        ],
        "name": "Reserve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "cost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "hiddenURL",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxFreeMintAmountPerWallet",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxMintAmountPerTx",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxSupply",
        "outputs": [
            {
                "internalType": "uint16",
                "name": "",
                "type": "uint16"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "_mintAmount",
                "type": "uint8"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "reveal",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_cost",
                "type": "uint256"
            }
        ],
        "name": "setCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "_limit",
                "type": "uint8"
            }
        ],
        "name": "setFreeMaxLimitPerAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_uriPrefix",
                "type": "string"
            }
        ],
        "name": "setHiddenUri",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "_maxtx",
                "type": "uint8"
            }
        ],
        "name": "setMaxMintAmountPerTx",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint16",
                "name": "_maxSupply",
                "type": "uint16"
            }
        ],
        "name": "setMaxSupply",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "setPaused",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "setRevealed",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_uriPrefix",
                "type": "string"
            }
        ],
        "name": "setUriPrefix",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

const oldAaddress = "0x9fb5D56adDa8B3625A7eCcc181D028a211e30D09";

const oldBABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalToCurrentOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApproveToCaller",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "OperatorNotAllowed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "_amountPerAddress",
                "type": "uint8"
            },
            {
                "internalType": "address[]",
                "name": "addresses",
                "type": "address[]"
            }
        ],
        "name": "Airdrop",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "NFTPerPublicAddress",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint16",
                "name": "_mintAmount",
                "type": "uint16"
            },
            {
                "internalType": "address",
                "name": "_receiver",
                "type": "address"
            }
        ],
        "name": "Reserve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "cost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "hiddenURL",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxFreeMintAmountPerWallet",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxMintAmountPerTx",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxSupply",
        "outputs": [
            {
                "internalType": "uint16",
                "name": "",
                "type": "uint16"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "_mintAmount",
                "type": "uint8"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "reveal",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_cost",
                "type": "uint256"
            }
        ],
        "name": "setCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "_limit",
                "type": "uint8"
            }
        ],
        "name": "setFreeMaxLimitPerAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_uriPrefix",
                "type": "string"
            }
        ],
        "name": "setHiddenUri",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "_maxtx",
                "type": "uint8"
            }
        ],
        "name": "setMaxMintAmountPerTx",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint16",
                "name": "_maxSupply",
                "type": "uint16"
            }
        ],
        "name": "setMaxSupply",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "setPaused",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "setRevealed",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_uriPrefix",
                "type": "string"
            }
        ],
        "name": "setUriPrefix",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

const oldBaddress = "0x5aF696307963dCEa7cd56F504C8C609BCd9E4502";

let contract;
let contractA;
let contractB;


class About extends Component {

    state = {
        walletAddress: "",
        totalSupply: "",
        currentPrice: "",
        nextPrice: "",
        nextSessionAmount: "",
        onlyLeftAmount: "",
        statusError: false,
        statusLoading: false,
        success: false,
        nftMintingAmount: '1',
        totalValue: "100000000000000000",
        maxmint: '',
        _adminPanel: 0,
        _adminWhitelistAddresses: [],
        _adminWhitelistAddresses2: [],
        _maxTokensToBuy: "",
        _testingValue: '',
        _wlMint: '',
        _FinalResult: '',
        _wlMsg: 'HOLDER CLAIM',
        _public_sale_cost: '',
        _MAX_PER_WALLET: '',
        _public_mint_status: '',
        _special_mint_status: '',
        _cost: '',
        _mintStatus: false,
        _paused: false,
        _total_wl_limit: '',
        _total_wl_count: '',
        _whitelistCost: '',
        _balanceA: '',
        _balanceB: '',
        _mintedFree: '',
        _freemintLimitPerWallet: '',
        _freemintLimit: '',
        _freemintCount: '',
    }


    walletConnect = async event => {
        event.preventDefault();

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
                }
            },
            coinbasewallet: {
                package: CoinbaseWalletSDK, // Required
                options: {
                    appName: "Aterium Universe", // Required
                    infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
                    rpc: "", // Optional if `infuraId` is provided; otherwise it's required
                    chainId: 1, // Optional. It defaults to 1 if not provided
                    darkMode: true // Optional. Use dark theme, defaults to false
                }
            }
        };

        const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions // required
        });



        const provider = await web3Modal.connect();

        //  Enable session (triggers QR Code modal)
        await provider.enable();

        const web3 = new Web3(provider);
        console.log("provider : " + provider);
        // Subscribe to accounts change
        provider.on("accountsChanged", (accounts) => {
            console.log(accounts);
        });

        // Subscribe to chainId change
        provider.on("chainChanged", (chainId) => {
            console.log(chainId);
        });

        // Subscribe to provider connection
        provider.on("connect", (info) => {
            console.log(info);
            console.log("I'm LOGGED IN");
        });

        // Subscribe to provider disconnection
        provider.on("disconnect", (error) => {
            console.log(error);
        });

        // test if wallet is connected
        if (web3Modal.cachedProvider) {
            // connected now you can get accounts
            console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
            console.log("provider :" + provider);

            const accounts = await web3.eth.getAccounts();

            account = accounts[0];
            this.setState({ walletAddress: account });

            contract = new web3.eth.Contract(ABI, address);
            contractA = new web3.eth.Contract(oldAABI, oldAaddress);
            contractB = new web3.eth.Contract(oldBABI, oldBaddress);

            console.log("contract :" + contract);

            if (provider) {


                //	(async () => {

                if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

                    const chainId = 1;

                    if (window.ethereum.networkVersion !== chainId) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: web3.utils.toHex(chainId) }],
                            });
                        } catch (err) {
                            // This error code indicates that the chain has not been added to MetaMask.
                            if (err.code === 4902) {
                                await window.ethereum.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainName: 'Ethereum Mainnet',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
                                            rpcUrls: ['https://mainnet.infura.io/v3/'],

                                            /*chainName: 'Mumbai Testnet',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                                            rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/

                                            /*chainName: 'Rinkeby Test Network',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
                                            rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
                                        },
                                    ],
                                });
                            }
                        }
                    }



                    try {

                        paused = await contract.methods.paused().call();
                        if (paused == true) {
                            paused = "true";
                        } else {
                            paused = "false";
                        }
                        this.setState({ _paused: paused });

                        totalSupplyNFT = await contract.methods.totalSupply().call();
                        this.setState({ totalSupply: totalSupplyNFT });
                        console.log("Total Supply:" + totalSupplyNFT);

                        publicSale = await contract.methods.publicMinted(account).call();
                        this.setState({ myNFTWallet: publicSale });

                        public_sale_cost = await contract.methods.publicSaleCost().call();
                        this.setState({ _public_sale_cost: public_sale_cost });
                        console.log("public_sale_cost :" + public_sale_cost);

                        MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
                        this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
                        console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

                        public_mint_status = await contract.methods.public_mint_status().call();
                        this.setState({ _public_mint_status: public_mint_status });
                        console.log("public_mint_status :" + public_mint_status);

                        special_mint_status = await contract.methods.special_mint_status().call();
                        this.setState({ _special_mint_status: special_mint_status });
                        console.log("special_mint_status :" + special_mint_status);

                        owner = await contract.methods.owner().call();
                        console.log("Owner" + owner);

                        total_wl_limit = await contract.methods.total_wl_limit().call();
                        this.setState({ _total_wl_limit: total_wl_limit });

                        total_wl_count = await contract.methods.total_wl_count().call();
                        this.setState({ _total_wl_count: total_wl_count });

                        balanceA = await contractA.methods.balanceOf(account).call();
                        this.setState({ _balanceA: balanceA });

                        balanceB = await contractB.methods.balanceOf(account).call();
                        this.setState({ _balanceB: balanceB });

                        if (owner == account) {
                            console.log("owner : " + owner)
                            onlyLeft = 1000 - totalSupplyNFT;

                            if (mintAmount > onlyLeft) {
                                mintAmount = onlyLeft;
                            }

                            valueOfNFTs = mintAmount * 0;


                            this.setState({ nftMintingAmount: mintAmount });

                            this.setState({ totalValue: valueOfNFTs });
                        } else {
                            mintAmount = 1;

                            if (totalSupplyNFT == 1000) {

                                onlyLeft = 1000 - totalSupplyNFT;
                                mintAmount = onlyLeft;
                                this.setState({ msg: "SOLD OUT" });

                            } else {
                                mintAmount = 1;
                                onlyLeft = MAX_PER_WALLET - publicSale;

                                if (mintAmount > onlyLeft) {
                                    mintAmount = onlyLeft;
                                }
                                //mintAmount = onlyLeft;

                                valueOfNFTs = mintAmount * public_sale_cost;

                                this.setState({ nftMintingAmount: mintAmount });

                                this.setState({ totalValue: valueOfNFTs });
                            }
                        }


                    } catch (err) {
                        console.log("err: " + err);

                    }
                } else if (web3Modal.cachedProvider == "walletconnect") {

                    const chainId = 1;

                    if (WalletConnectProvider.networkVersion !== chainId) {
                        try {
                            await WalletConnectProvider.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: web3.utils.toHex(chainId) }],
                            });
                        } catch (err) {
                            // This error code indicates that the chain has not been added to MetaMask.
                            if (err.code === 4902) {
                                await window.ethereum.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainName: 'Ethereum Mainnet',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
                                            rpcUrls: ['https://mainnet.infura.io/v3/'],

                                            /*chainName: 'Mumbai Testnet',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                                            rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/

                                            /*chainName: 'Mumbai Testnet',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                                            rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
                                        },
                                    ],
                                });
                            }
                        }
                    }


                    try {

                        paused = await contract.methods.paused().call();
                        if (paused == true) {
                            paused = "true";
                        } else {
                            paused = "false";
                        }
                        this.setState({ _paused: paused });

                        totalSupplyNFT = await contract.methods.totalSupply().call();
                        this.setState({ totalSupply: totalSupplyNFT });
                        console.log("Total Supply:" + totalSupplyNFT);

                        publicSale = await contract.methods.publicMinted(account).call();
                        this.setState({ myNFTWallet: publicSale });

                        public_sale_cost = await contract.methods.publicSaleCost().call();
                        this.setState({ _public_sale_cost: public_sale_cost });
                        console.log("public_sale_cost :" + public_sale_cost);

                        MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
                        this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
                        console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

                        public_mint_status = await contract.methods.public_mint_status().call();
                        this.setState({ _public_mint_status: public_mint_status });
                        console.log("public_mint_status :" + public_mint_status);

                        special_mint_status = await contract.methods.special_mint_status().call();
                        this.setState({ _special_mint_status: special_mint_status });
                        console.log("special_mint_status :" + special_mint_status);

                        owner = await contract.methods.owner().call();
                        console.log("Owner" + owner);

                        total_wl_limit = await contract.methods.total_wl_limit().call();
                        this.setState({ _total_wl_limit: total_wl_limit });

                        total_wl_count = await contract.methods.total_wl_count().call();
                        this.setState({ _total_wl_count: total_wl_count });

                        balanceA = await contractA.methods.balanceOf(account).call();
                        this.setState({ _balanceA: balanceA });

                        balanceB = await contractB.methods.balanceOf(account).call();
                        this.setState({ _balanceB: balanceB });

                        if (owner == account) {
                            console.log("owner : " + owner)
                            onlyLeft = 1000 - totalSupplyNFT;

                            if (mintAmount > onlyLeft) {
                                mintAmount = onlyLeft;
                            }

                            valueOfNFTs = mintAmount * 0;



                            this.setState({ nftMintingAmount: mintAmount });

                            this.setState({ totalValue: valueOfNFTs });
                        } else {
                            mintAmount = 1;

                            if (totalSupplyNFT == 1000) {

                                onlyLeft = 0;

                                if (mintAmount > onlyLeft) {
                                    mintAmount = onlyLeft;
                                }
                                this.setState({ msg: "SOLD OUT" });

                            } else {
                                mintAmount = 1;
                                onlyLeft = MAX_PER_WALLET - publicSale;

                                if (mintAmount > onlyLeft) {
                                    mintAmount = onlyLeft;
                                }

                                valueOfNFTs = mintAmount * public_sale_cost;


                                this.setState({ nftMintingAmount: mintAmount });

                                this.setState({ totalValue: valueOfNFTs });
                            }
                        }


                    } catch (err) {
                        console.log("err: " + err);

                    }
                } else if (web3Modal.cachedProvider == "coinbasewallet") {

                    const chainId = 1;

                    if (CoinbaseWalletProvider.networkVersion !== chainId) {
                        try {
                            await CoinbaseWalletProvider.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: web3.utils.toHex(chainId) }],
                            });
                        } catch (err) {
                            // This error code indicates that the chain has not been added to MetaMask.
                            if (err.code === 4902) {
                                await CoinbaseWalletProvider.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainName: 'Ethereum Mainnet',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
                                            rpcUrls: ['https://mainnet.infura.io/v3/'],

                                            /*chainName: 'Mumbai Testnet',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                                            rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/

                                            /*chainName: 'Mumbai Testnet',
                                            chainId: web3.utils.toHex(chainId),
                                            nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                                            rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
                                        },
                                    ],
                                });
                            }
                        }
                    }



                    try {

                        paused = await contract.methods.paused().call();
                        if (paused == true) {
                            paused = "true";
                        } else {
                            paused = "false";
                        }
                        this.setState({ _paused: paused });

                        totalSupplyNFT = await contract.methods.totalSupply().call();
                        this.setState({ totalSupply: totalSupplyNFT });
                        console.log("Total Supply:" + totalSupplyNFT);

                        publicSale = await contract.methods.publicMinted(account).call();
                        this.setState({ myNFTWallet: publicSale });

                        public_sale_cost = await contract.methods.publicSaleCost().call();
                        this.setState({ _public_sale_cost: public_sale_cost });
                        console.log("public_sale_cost :" + public_sale_cost);

                        MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
                        this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
                        console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

                        public_mint_status = await contract.methods.public_mint_status().call();
                        this.setState({ _public_mint_status: public_mint_status });
                        console.log("public_mint_status :" + public_mint_status);

                        special_mint_status = await contract.methods.special_mint_status().call();
                        this.setState({ _special_mint_status: special_mint_status });
                        console.log("special_mint_status :" + special_mint_status);

                        owner = await contract.methods.owner().call();
                        console.log("Owner" + owner);

                        total_wl_limit = await contract.methods.total_wl_limit().call();
                        this.setState({ _total_wl_limit: total_wl_limit });

                        total_wl_count = await contract.methods.total_wl_count().call();
                        this.setState({ _total_wl_count: total_wl_count });

                        balanceA = await contractA.methods.balanceOf(account).call();
                        this.setState({ _balanceA: balanceA });

                        balanceB = await contractB.methods.balanceOf(account).call();
                        this.setState({ _balanceB: balanceB });

                        if (owner == account) {
                            console.log("owner : " + owner)
                            onlyLeft = 1000 - totalSupplyNFT;

                            if (mintAmount > onlyLeft) {
                                mintAmount = onlyLeft;
                            }

                            valueOfNFTs = mintAmount * 0;


                            this.setState({ nftMintingAmount: mintAmount });

                            this.setState({ totalValue: valueOfNFTs });
                        } else {
                            mintAmount = 1;

                            if (totalSupplyNFT == 1000) {

                                onlyLeft = 1000 - totalSupplyNFT;
                                mintAmount = onlyLeft;
                                this.setState({ msg: "SOLD OUT" });

                            } else {
                                mintAmount = 1;
                                onlyLeft = MAX_PER_WALLET - publicSale;

                                if (mintAmount > onlyLeft) {
                                    mintAmount = onlyLeft;
                                }
                                //mintAmount = onlyLeft;

                                valueOfNFTs = mintAmount * public_sale_cost;

                                this.setState({ nftMintingAmount: mintAmount });

                                this.setState({ totalValue: valueOfNFTs });
                            }
                        }


                    } catch (err) {
                        console.log("err: " + err);

                    }


                }


                //})();

                //......................................................................//

                // Legacy providers may only have ethereum.sendAsync
                const chainId = await provider.request({
                    method: 'eth_chainId'
                })

            } else {

                // if the provider is not detected, detectEthereumProvider resolves to null
                console.error('Please install a Valid Wallet');
                alert('A valid provider could not be found!');

            }

        }

    }

    walletDisconnect = async event => {
        event.preventDefault();


        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    infuraId: "9aad89c8e515457ab8b7805f5da593ea" // required
                }
            }
        };

        const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions // required
        });



        // disconnect wallet
        web3Modal.clearCachedProvider();
        window.location.reload();

    }

    render() {

        return (

            <div class="stakeMainPage">
                <div class="aboutPageMain">
                    <div class="navBarStake">
                        <div class="navBarStake2">
                            <div class="tName"><img class="logoNFT" onClick={() => window.location.href = '/'} src={logo} /></div>

                            <div class="connect">
                                <div id="fontSize" onClick={() => window.location.href = '/mint'}>Mint/Claim</div>
                                <div id="fontSize" onClick={() => window.location.href = '/stake'}>Stake</div>
                                <div id="fontSizeActive">About</div>
                                <div id="fontSize" onClick={() => window.location.href = '/marketplace'}>Marketplace</div>
                                <div id="fontSize" onClick={() => window.location.href = '/dao'}>DAO</div>
                                <div id="fontSize" onClick={twitter}>Twitter</div>
                                <div id="fontSize" onClick={discord}>Discord</div>
                            </div>

                            <div class="connectMobile">
                                <div id="fontSize" onClick={() => window.location.href = '/mint'}>Mint/Claim</div>
                                <div id="fontSize" onClick={() => window.location.href = '/stake'}>Stake</div>
                                <div id="fontSizeActive" onClick={() => window.location.href = '/about'}>About</div>
                                <div id="fontSize" onClick={() => window.location.href = '/marketplace'}>Marketplace</div>
                                <div id="fontSize" onClick={() => window.location.href = '/dao'}>DAO</div>
                            </div>

                            <div class="walletAndMint">
                                {this.state.walletAddress === '' ?
                                    (<form class="stakeWCMain" onSubmit={this.walletConnect}>
                                        <button class="kave-btn">
                                            <span class="kave-line"></span>
                                            Connect
                                        </button>
                                    </form>) : (<form class="stakeWCMain" onSubmit={this.walletDisconnect}><button class="kave-btn">
                                        <span class="kave-line"></span>
                                        {this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

                                    </button>

                                    </form>)}
                            </div>
                        </div>
                    </div>

                    <div class="aboutPageMain2">
                        <p><div class="glitch" data-text="Hello Anon,">Hello Anon,</div></p>
                    </div>

                    <div class="aboutP">
                        <p>You have made it and I am so happy for your attendance. The premise is simple, who I am is not important, the art is. Our next performance will include your visual identity within our ecosystem. anon is an artist, anon is an ecosystem, anon is a person, anon is a team, anon is a platform. But again, who I am is not important, the art is.</p>
                        <p>This is our belief and this will always be our focus. Here at the anon HQ, holders of Abstraction and WIAINITAI can stake their NFT to earn our in house currency $ANON. As of now $ANON can only be used within our marketplace that will be stocked up with physical goods, web3 benefits, nft tools, future whitelists, and so much more that you can purchase. Again, with a focus solely on art.</p>
                        <p>Art is why we are here and we will continue to curate the best experiences, performances, and memories for everyone in attendance. As we progress, you may have noticed a black box that reads "KEEP OUT" has surfaced. What could be inside?</p>
                        <p>Holder's of <a href="https://opensea.io/collection/abstraction-by-anon">Abstraction</a> will be able to claim 3 boxes free for every 1 Abstraction they hold.</p>
                        <p>Holder's of <a href="https://opensea.io/collection/who-i-am-is-not-important-the-art-is-by-anon">WIAINITAI</a> will be able to claim 1 free box for every 1 WIAINITAI they hold.</p>
                        <p>Once a token has been claimed, it can not be claimed again.</p>
                        <p>After claim, holders are welcome to stake their Abstraction or WIAINITAI and will immediately earn $ANON.</p>
                        <p>Those that have created, shared, and posted will be airdropped a black box for their service and commitment to art. The public will have a chance to mint a maximum of 3 boxes at 0.09 each on at 3:33 PM PST.</p>
                        <p>The initiation process to your visual identity will be a "manual reveal" taking place on before the end of the year.</p>
                        <p>I look forward to your attendance.</p>

                        <p></p>
                        <div class="aboutDiv">
                            <p>Abstraction = Earn 25 $ANON</p>
                            <p>WIAINITAI = Earn 5 $ANON</p>
                            <p>ANON by anon = Earn 1 $ANON</p>
                        </div>
                    </div>

                    <div class="cMain">
                        <p class="cLine"></p>
                        <img src={logo} />
                        <div>© 2022 ANON. All rights reserved.</div>
                        <p class="cLine"></p>
                    </div>

                </div>

            </div>)
    }
}

export default About;
